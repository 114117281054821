<template>
  <div class="app" id="app">
    <router-view></router-view>
    <!-- <el-container>
      <el-header class="sticky-header" height="76px" :style="myStyle">
        <headers></headers>
      </el-header>

      <el-main style="margin-top: 80px">
        <router-view></router-view>
      </el-main>

      <el-footer>
        <footers></footers>
      </el-footer>
    </el-container> -->
  </div>
</template>

<script>
import headers from "./components/layout/headers.vue";
import footers from "./components/layout/footers.vue";
import axios from "axios";

export default {
  data() {
    return {
      aa: "",
      myStyle: {
        background: "",
        backdropFilter: "",

        result: [],
      },
    };
  },
  components: {
    // headers,
    headers,
    footers,
  },

  mounted() {
    this.getListAll();

    //  console.log("啊啊"+this.$globalVariable);
    // this.disableZoom(); // 在组件挂载后调用禁止缩放的方法
  },
  methods: {
    getListAll() {
      return
      // console.log(this.$globalVariable); // 输出：这是一个全局变量
      // 在方法中发起请求
      axios
        .get("http://api.tesla111.com:8083/server/api/gameConfig/info")
        .then((response) => {
          // 定义全局变量存放背景图
          this.aa = response.data.data.backgroundImage;
          const appElement = document.querySelector(".app");
          appElement.style.backgroundImage = `url(${this.aa})`;
        })
        .catch((error) => {
          // 处理请求错误
          console.error("Error fetching data:", error);
        });
    },
    // disableZoom() {
    //   window.addEventListener("wheel", this.preventZoom, { passive: false }); // 添加滚轮事件监听
    // },
    // preventZoom(event) {
    //   if (event.ctrlKey === true) {
    //     // 如果按下了Ctrl键
    //     event.preventDefault(); // 阻止默认行为
    //   }
    // },
  },
  // beforeDestroy() {
  //   window.removeEventListener("wheel", this.preventZoom); // 组件销毁前移除事件监听
  // },
};
</script>

<style lang="less">
.app {
  /* height: 100%; */
  /* height: 100vh; */
  /* background-image: url('@/static/img/10023.png'); */
  /* background-repeat: no-repeat; 
    background-position:contain; */
  /* zoom: 1;  */
  background-color: #0a0815;
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  height: 100vh;
  /* background-image:url("maskimg/star.jpg");  */
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover; /* 兼容Opera */
  zoom: 1;
}
</style>
