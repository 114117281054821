<template>
  <div class="header">
    <!--  -->
    <el-row>
      <el-col :span="4" :md="4" :lg="4" :xl="5"
        ><div class="grid-content bg-purple-light">
          <img class="all-log-img" src="@/assets/logo.png" /></div
      ></el-col>
      <el-col :span="2"
        ><div class="grid-content bg-purple-light">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div></el-col
      >
      <el-col :span="2" :md="4" :lg="3" :xl="5">
        <div class="grid-content bg-purple-light">
          <router-link class="homess" to="/home">首 页</router-link>
        </div>
      </el-col>
      <el-col :span="2" :md="4" :lg="3" :xl="3">
        <div class="grid-content bg-purple-light">
          <router-link class="aboutss" to="/CheatList">游戏列表</router-link>
        </div>
      </el-col>
      <el-col :span="10"
        ><div class="grid-content bg-purple-light">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div></el-col
      >
      <el-col :span="4" :md="3" :lg="4" :xl="4"
        ><div class="grid-content bg-purple-light">
          <button class="dowload" @click="downloadSetup">下 载</button>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //log地址
      logsrc: "",
      // 下载地址
      downloadUrl: "",
    };
  },
  components: {},
  mounted() {
    this.getLogAndDowload();
    //动画
  },

  methods: {
    getLogAndDowload() {
      // this.logsrc = '@/assets/logo.png';
      this.downloadUrl = "";
      return;
      // 在方法中发起请求
      axios
        .get("http://api.tesla111.com:8083/server/api/gameConfig/header")
        .then((response) => {
          // 处理返回的数据
          this.logsrc = response.data.data.logoUrl;
          //  console.log(response.data.data);
          this.downloadUrl = response.data.data.downloadUrl;
        })
        .catch((error) => {
          // 处理请求错误
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style>
/* 当屏幕宽度小于600px时，修改样式 */
.header {
  /* position: absolute;
  top: 0;
  margin: 0 20px; */

  height: var(--heightHead);
  width:  var(--widthCommon);
  /* display: flex; */
  margin-top: 3px;
  /* align-items: center; */
}

.all-log-img {
  width: 200px;
  /* height: 30px; */
  position: relative;
  top: 5px;
  text-decoration: none;
  /* font-size: 16px; */
  font-weight: 1000;
}
.dowload:hover {
  box-shadow: inset 0 0 10px 1px #ee6d03;
}
.dowload {
  color: #f8f8f8;
  font-weight: 700;
  position: relative;
  top: 1px;
  /* top: -10px; */
  /* width: 100px; */
  width: 100px;
  height: 43px;
  border: none;
  border-radius: 6px;
  border: 1px solid white;
  background-color: var(--backgroundColorDefault2);
  box-shadow: inset 0 0 4px 0px #ccc;
  /* background-image: url("img/10001.jpg"); */
  transition: all 0.2s ease 0s;
  margin-right: 0;
  min-width: 100px;
  z-index: 200;
}
.all-btn {
  z-index: 200;
  /* top: -20px; */
  position: absolute;
  border-radius: 5px;
  width: 120px;
  height: 50px;
}
a {
  color: #99a9bf;
}
.homess {
  position: relative;
  top: 5px;
  text-decoration: none;
  /* font-size: 16px; */
  font-weight: 1000;
}
.router-link-active {
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.aboutss {
  position: relative;
  top: 5px;
  text-decoration: none;
  font-weight: 1000;
  /* font-size: 20px; */
}
.el-row {
  height: 100%;
  display: flex;
  align-items: center; /* 上下居中 */
  justify-content: center; /* 水平居中 */
}
.el-col {
  height: 100%;
  display: flex;
  align-items: center; /* 上下居中 */
  justify-content: center; /* 水平居中 */
}
</style>
