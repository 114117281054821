<template>
  <body style="color: white; width: var(--widthCommon);">
    <div class="bDyiFu">
      <div class="protocol-item">
        <a :href="homeUrl">网站首页</a>
      </div>
      <div class="protocol-item" @click="downloadSetup">
        修改器下载
      </div>
      <router-link to="/privacy" class="protocol-item"
        >隐私政策</router-link
      >
      <router-link to="/term" class="protocol-item"
        >用户权限</router-link
      >
      <div class="protocol-item">开发者： ©️ OneBuff Community</div>
      <div class="protocol-item">版 本： 8.3.5</div>
    </div>
    <div class="kUUIIg">
      <p>© OneBuff LLC</p>
      <br>
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
        <p>渝ICP备2023004740号-1</p>
      </a>
    </div>
  </body>
</template>

<script setup>
const homeUrl = process.env.VUE_APP_URL_HOME
</script>

<style>
.iwmdxe {
  width: 100%;

  bottom: 0px;
  left: 0px;
  background: rgba(203, 9, 9);
}
.bDyiFu {
  display: flex;

  align-items: center;

  justify-content: center;
  margin: 0px auto;
  /* height: 70px; */
}
.bDyiFu .protocol-item:not(:last-child) {
  margin-right: 120px;
}
.bDyiFu .protocol-item {
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  color: #ffffff65;
  cursor: pointer;
}
a {
  color: #ffffff65;
  text-decoration: none;
}

.kUUIIg {
  width:  var(--widthcommon);
  margin: 16px auto;
  /* display: flex; */
  /* height: 40px; */

  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.kUUIIg p {
  font-size: 16px;
  line-height: 20px;
  color: #99a9bf;
  text-align: center;
  margin-bottom: 0px;
}
</style>