<template>
  <div class="aaa">
    <el-row>
      <el-col :span="24" class="all">
        <div class="grid-content bg-purple-dark">
          <el-container>
            <el-header class="sticky-header" height="var(--heightHead)" :style="myStyle">
              <headers></headers>
            </el-header>

            <el-main>
              <router-view></router-view>
            </el-main>

            <el-footer height="120px">
              <footers></footers>
            </el-footer>
          </el-container>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import axios from "axios";
import headers from "./headers.vue";
import footers from "./footers.vue";
export default {
  data() {
    return {
      myStyle: {
        background: "",
        backdropFilter: "",

        result: [],
      },
    };
  },
  components: {
    headers,
    footers,
  },
  methods: {},
  mounted() {
    // 滚动监听
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    return;
    // this.getListAllGameName();
    // this.getListAll();
  },
  methods: {
    // getListAll() {
    //   // console.log(this.$globalVariable); // 输出：这是一个全局变量
    //   // 在方法中发起请求
    //   axios
    //     .get("http://api.tesla111.com:8083/server/api/gameConfig/info")
    //     .then((response) => {
    //       //定义全局变量存放背景图
    //       this.aa = response.data.data.backgroundImage;
    //       const appElement = document.querySelector(".aaa");
    //       appElement.style.backgroundImage = `url(${this.aa})`;
    //     })
    //     .catch((error) => {
    //       // 处理请求错误
    //       console.error("Error fetching data:", error);
    //     });
    // },
    // //获取所有数据方法
    // getListAllGameName() {
    //   // 在方法中发起请求
    //   axios
    //     .get(
    //       "http://api.tesla111.com:8083/server/api/game/list?encryptedStr=c8JzeVsB+fbkvbPpfaLOpjIwlrLP+Lutg/8Sv9QxeHesh/+C7zv0yuaXWfDFBQ0L"
    //     )
    //     .then((response) => {
    //       // 在Vue组件中的某个方法内调用解密函数

    //       // console.log(response.data);

    //       // 待解密的密文
    //       let ciphertext = response.data.data; // 替换为实际的密文

    //       // 密钥
    //       let key = "FHb57G4pjheYMajY"; // 替换为实际的密钥

    //       // 将密文使用 Base64 解码
    //       let encryptedData = CryptoJS.enc.Base64.parse(ciphertext);

    //       // 设置解密配置，这里假设使用 AES ECB 模式
    //       let decryptConfig = {
    //         mode: CryptoJS.mode.ECB,
    //         padding: CryptoJS.pad.Pkcs7,
    //       };

    //       // 解密
    //       let decrypted = CryptoJS.AES.decrypt(
    //         { ciphertext: encryptedData },
    //         CryptoJS.enc.Utf8.parse(key),
    //         decryptConfig
    //       );

    //       // 获取解密后的明文
    //       let plaintext = decrypted.toString(CryptoJS.enc.Utf8);

    //       // 输出解密结果
    //       // console.log( JSON.parse(plaintext) );

    //       // console.log(plaintext);
    //       const aaa = JSON.parse(plaintext);
    //       //  console.log(aaa);
    //       localStorage.setItem("ListAllGameData", plaintext);
    //     })
    //     .catch((error) => {
    //       // 处理请求错误
    //       console.error("Error fetching data:", error);
    //     });
    // },
    //  cthis : this,
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop, 100)
      //控制顶部导航栏
      if (scrollTop > 80) {
        this.myStyle.background = "rgba(17, 23, 29, 0.5)";
        this.myStyle.backdropFilter = "blur(15px)";
      } else if (scrollTop < 80) {
        this.myStyle.background = "none";
        this.myStyle.backdropFilter = "";
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.aaa {
  /* display: flex; */
  /* background-position: center 0; */
  /* align-items: center; */
  /* background-size: 100% 100%; */
  /* justify-content: center; 实现横向居中 */
   /* 设置容器高度为视口高度，实现垂直居中 */
  /* height: 100%; */
  background-color: #0A0815;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  zoom: 1;

  /* width: 100%;
  height: 638px;
  background: url(@/static/img/background.png) center top / cover no-repeat;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 85px; */
}

.sticky-header {
  /* height: 100vh; */
  border-radius: 0px 0px 10px 10px;
  /* height: 200px; */
  position: fixed;
  top: 0;
  /* width: 100%; */
  /* left: 0;
  right: 0; */
  z-index: 100;

  /* 可以根据需要设置其他样式，比如背景色、阴影等 */
}
</style>
