

// 作者：紫圣
// 链接：https://juejin.cn/post/7039109468080062500
// 来源：稀土掘金
// 著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
function download(blob, filename) {
  const a = document.createElement('a')
  a.download = filename
  const blobUrl = URL.createObjectURL(blob)
  a.href = blobUrl
  document.body.appendChild(a)
  a.click()
  a.remove()
  URL.revokeObjectURL(blobUrl)
}
function downloadFile(url, filename) {
  if(!filename) {
    let lst = url.split('/')
    if (lst.length > 0) {
      filename = decodeURI(lst[lst.length-1])
    } else {
      filename = '欢迎使用OneBuff'
    }
  }
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: 'no-cors',
  })
  .then(res => {
    // 本地测试0，服务器上是200
    if( res.status === 200 || res.status === 0 ) {
      // 返回的.blob()为promise，然后生成了blob对象，此方法获得的blob对象包含了数据类型，十分方便
      return res.blob()
    }
    throw new Error(`status: ${res.status}.`)
  })
  .then(blob => {
    download(blob, filename)
  })
}

function downloadSetup() {
  return downloadFile(process.env.VUE_APP_URL_DOWNLOAD)
  console.log('downloadSetup')
  const url = process.env.VUE_APP_URL_DOWNLOAD;
  const link = document.createElement("a");
  link.href = url;
  link.download=''
  link.setAttribute("download", "");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadSetup1() {
  // 从本地存储中获取数据
  const download = localStorage.getItem("downloadU");

  const url = download;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  // if (('' + time).length === 10) {
  //   time = parseInt(time) * 1000
  // } else {
  //   time = +time
  // }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  } else if (diff < 3600 * 24 * 30) {
    return Math.ceil(diff / 3600 / 24) + '天前'
  } else if (diff < 3600 * 24 * 365) {
    return Math.ceil(diff / 3600 / 24 / 30) + '月前'
  }
  
  return (
    d.getFullYear() + '年' +
    d.getMonth() +
    1 +
    '月' +
    d.getDate() +
    '日'
    //  +
    // d.getHours() +
    // '时'
    //  +
    // d.getMinutes() +
    // '分'
  )
}

export { downloadSetup, formatTime }
