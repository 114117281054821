import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/static/less/var.css';
import store from './store';

//css样式
import css from '../src/static/allcss.css'
//背景图全局变量
// Vue.prototype.$globalVariable = '这是一个全局变量';
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { downloadSetup } from '@/utils'
Vue.prototype.downloadSetup = downloadSetup
